<template>
  <div class="resume">
    <div class="grid">
      <div class="grid__col grid__col--7-of-12 mb--m">
        <h2 class="label">Bio</h2>
        <p class="p">
          Tobias is a graphic designer based in Stockholm, with interest
and knowledge in front-end development. Born and raised in the
northern parts of Sweden, he early on found an interest in
computers and their creative possibilities which gradually shaped
into a never-ending love for graphic design.
        </p>
        <p class="p indent">
          He graduated with a BFA in Visual Communication at Beckmans
College of Design in 2017 and freelanced as a designer and
developer for several years. Simultaneously, he co-founded and
worked on the design studio Ekstra before joining Kurppa Hosk
as a digital designer in 2020.
        </p>
        <p class="p indent">
          Tobias enjoys working interdisciplinary with design and code,
aiming for an experience that is both visually and technically
exciting. He merges his craftsmanship in graphic design with
modern and interactive approaches from the world of code.
        </p>
      </div>
      <div class="grid__col grid__col--4-of-12 grid__col--push-1-of-12">
        <h2 class="label">Selected Clients</h2>
        <p class="p h2 max-width">Nike, Telia, Securitas, Alleima, Pandora, Altor, Dinesen, Tink, Sesamy, Yabie, Happify</p>
      </div>
    </div>
    <div class="grid">
      <div class="grid__col grid__col--4-of-12 mb--m">
        <h2 class="label">Education</h2>
        <h3 class="p">BFA in Visual Communication at <a href="https://beckmans.se/" target="_blank">Beckmans College of
            Design</a>, Stockholm, Sweden.</h3>
        <p class="p indent serif">2014—2017</p>
      </div>
      <div class="grid__col grid__col--4-of-12 mb--m">
        <h2 class="label">Work Experience</h2>
        <div class="max-width">
          <h3 class="p">Senior Digital Designer and Web Developer at <a target="_blank"
              href="https://www.kurppahosk.com">Kurppa
              Hosk</a>, Stockholm, Sweden.</h3>
          <p class="p indent serif">2020—Present</p>
        </div>
        <div class="max-width">
          <h3 class="p">Graphic Designer, Web Developer and Co-Founder at <a target="_blank"
              href="https://www.ekstra.studio">Ekstra</a>, Stockholm, Sweden.</h3>
          <p class="p indent serif">2017—2020</p>
        </div>
        <div class="max-width">
          <h3 class="p">Freelancing as Graphic Designer and Web Developer.</h3>
          <p class="p indent serif">2017—2020</p>
        </div>
        <div class="max-width">
          <h3 class="p">Internship as Graphic Designer and Web Developer at <a target="_blank"
              href="https://www.christopherwest.se">Studio Christopher West</a>, Stockholm, Sweden.</h3>
          <p class="p indent serif">2016</p>
        </div>
        <div class="max-width">
          <h3 class="p">Internship as Graphic Designer and Web Developer at <a target="_blank"
              href="https://www.kodochform.se">Kod & Form</a>, Stockholm, Sweden.</h3>
          <p class="p indent serif">2016</p>
        </div>
      </div>
      <div class="grid__col grid__col--4-of-12">
        <h2 class="label">Scholarships</h2>
        <div class="max-width">
          <h3 class="p">Scholarship from Beckmans College of Design for graduation project Jamska.</h3>
          <p class="p indent serif">2017</p>
        </div>
        <div class="max-width">
          <h3 class="p">Scholarship from Åkestam Holst for graduation project Jamska.</h3>
          <p class="p indent serif">2017</p>
        </div>
        <div class="max-width">
          <h3 class="p">Scholarship from King for graduation project Jamska.</h3>
          <p class="p indent serif">2017</p>
        </div>
      </div>
    </div>
    <div class="grid">
      <div class="grid__col grid__col--4-of-12 mb--m">
        <h2 class="label">Software Experience</h2>
        <div class="max-width">
          <h3 class="p">Adobe Creative Suite</h3>
          <p class="p indent serif">Photoshop</p>
          <p class="p indent serif">Illustrator</p>
          <p class="p indent serif">InDesign</p>
          <p class="p indent serif">Premiere Pro</p>
          <p class="p indent serif">After Effects</p>
        </div>
        <div class="max-width">
          <h3 class="p">Other</h3>
          <p class="p indent serif">Figma</p>
          <p class="p indent serif">Blender</p>
        </div>
      </div>
      <div class="grid__col grid__col--4-of-12">
        <h2 class="label">Coding Experience</h2>
        <div class="max-width">
          <h3 class="p">Languages</h3>
          <p class="p indent serif">HTML</p>
          <p class="p indent serif">CSS/SCSS</p>
          <p class="p indent serif">JavaScript</p>
          <p class="p indent serif">PHP</p>
          <p class="p indent serif">Markdown</p>
        </div>
        <div class="max-width">
          <h3 class="p">Libraries & Frameworks</h3>
          <p class="p indent serif">Vue</p>
          <p class="p indent serif">GSAP</p>
          <p class="p indent serif">Wordpress</p>
          <p class="p indent serif">jQuery</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
}
</script>

<style lang="scss" scoped>
.resume {
  padding-top: 64px * 2;
}

.grid,
.mb--m {
  margin-bottom: 64px;

  @media only screen and (max-width: 935px) {
    margin-bottom: 16px * 3;
  }
}

.resume {
  padding-bottom: 64px;

  @media only screen and (max-width: 935px) {
    padding-bottom: 16px * 3;
  }
}</style>