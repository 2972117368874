<template>
    <nav>
        <div class="grid">
                    <div class="grid__col grid__col--6-of-12">
                <h1 class="label color--tint">Tobias Gerhardsson</h1>
                <h2 class="label color--tint">Graphic designer & front-end developer</h2>
            </div>
            <div class="grid__col grid__col--2-of-12 grid__col--m-3-of-6 grid__col--s-2-of-6">
                <h1 class="label color--tint">Telephone</h1>
                <h1 class="label color--tint">Email</h1>
            </div>
            <div class="grid__col grid__col--2-of-12 grid__col--m-3-of-6 grid__col--s-4-of-6">
                <h2 class="label color--tint"><a href="tel:+46(0)705 17 65 49">+46(0)705176549</a></h2>
                <h2 class="label color--tint"><a href="mailto:tobias.gerhardsson@gmail.com">tobias.gerhardsson<wbr>@gmail.com</a></h2>
            </div>
            <div class="grid__col grid__col--2-of-12 text-right">
                <div v-if="isResume"><span class="label nav--item hover" @click="goBack"><Icon icon="arrow-left" /> Back</span></div>
                <div v-else><router-link class="label nav--item hover" to="/resume">About <Icon icon="arrow-right" align="right" /></router-link></div>
            </div>
        </div>

    </nav>
</template>

<script>
import Icon from '@/components/Icon'
export default {
    components: {
        Icon
    },
    computed: {
        isResume(){
        return this.$route.name === 'Resume'
        },
    },
    methods: {
        goBack(){
            this.$router.go(-1)
        }
    }
}
</script>

<style lang="scss" scoped>
    nav {
        position:absolute;
        top:8px;
        left:0;
        right:0;
        z-index:99;
        background-color:white;
    }
    .label {
        line-height:100%;
        padding:0;
        margin:0;
        a {
            color:#000;
            text-decoration: none;
            &:hover {
                opacity:0.5;
            }
        }
    }
    .nav--item {
        line-height:85%;
        display: inline;
        color:black;
        vertical-align: top;
        text-decoration: none;
    }
    wbr {
        display:none;
    }
    @media only screen and (max-width: 935px) { 
        .text-right {
            position:absolute;
            top:1px;
            right:0;
        }
        wbr {
            display:block;
        }
    }
</style>