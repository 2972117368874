<template>
  <div id="app">
    <Navigation />
    <router-view/>
  </div>
</template>

<script>
import Navigation from '@/components/Navigation'
export default {
  components: {
    Navigation
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: 'HelveticaNowText-Regular';
  src: url('./fonts/HelveticaNowText-Regular.eot');
  src: url('./fonts/HelveticaNowText-Regular.eot?#iefix') format('embedded-opentype'),
       url('./fonts/HelveticaNowText-Regular.svg#HelveticaNowText-Regular') format('svg'),
       url('./fonts/HelveticaNowText-Regular.ttf') format('truetype'),
       url('./fonts/HelveticaNowText-Regular.woff') format('woff'),
       url('./fonts/HelveticaNowText-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

html {
  font-size:150%;
  scroll-behavior: smooth;
}
body, html, #app {
  height:100%;
  width:100%;
  line-height:110%;
}
#app {
  font-family:"HelveticaNowText-Regular", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight:400;
  letter-spacing:-0.02em;
   -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*, *:after, *:before {
  margin:0;
  padding:0;
  box-sizing:border-box;
}
</style>
