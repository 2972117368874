<template>
                <figure class="full-bleed">
                        <cld-video cloud-name="tobiasgerhardsson-com" autoplay="true" loop="true" muted="true" playsinline :public-id="videoURL" autoplayMode="on-visible" :quality="50" />
                </figure>
</template>

<script>
export default {
    props: ["source"],
    computed: {
        videoURL(){
            return `portfolio/${this.source}`
        }
    }
}
</script>

<style lang="scss" scoped>
    figure {
        background-color:#f0f0f0;
        width:100%;
        position: relative;
        img, video {
            display:block;
            width:100%;
            height:auto;
        }
    }
</style>