<template>
                <figure class="full-bleed">
                        <cld-image 
                            :public-id="imageURL" 
                            loading="lazy"
                            width="100%"
                            height="100%"
                            >
                            <cld-placeholder type="predominant-color"></cld-placeholder>
                        </cld-image>
                </figure>
</template>

<script>
export default {
    props: ["source"],
    computed: {
        imageURL(){
            return `portfolio/${this.source}`
        }
    }
}
</script>

<style lang="scss" scoped>
    figure {
        background-color:#f0f0f0;
        width:100%;
        position: relative;
        img, video {
            display:block;
            width:100%;
            height:auto;
        }
    }
</style>