<template>
    <div class="portfolio">
        <section class="project" id="opentensor">
            <div class="grid project__header">
                <div class="grid__col grid__col--6-of-12">
                    <h2 class="p project__title">Opentensor</h2>
                    <p class="serif">Web Design, Product Design</p>
                </div>
                <div class="grid__col grid__col--6-of-12">
                    <p class="p">Opentensor, dedicated to accessible AI, collaborated with Kurppa Hosk to forge a digital design toolkit.</p>
                    <p class="indent">Recognizing the need for a compelling digital presence, Kurppa Hosk was asked to help and craft the foundation of a "less is more" design system, leveraging minimalism, raw aesthetics, and technical precision.</p>
                    <p class="indent">This versatile framework seamlessly extended to crucial touchpoints, including a TAO currency wallet for secure transactions, Tensorscan – a visually cohesive ledger, and the Bittensor developer documentation.</p>
                </div>
            </div>
            <div class="grid project__container project__content">
                <div class="grid__col grid__col--portfolio grid__col--12-of-12">
                    <Slideshow :interval="2000"
                        :images="['iyazsm4rwd6nu7k2kzx1', 'z2oftu4xyzngujcfoknb', 'dsbhoplhuqibpewsqejp']" bg="#EFEFEF" />
                </div>
            </div>
            <div class="grid project__container project__content">
                <div class="grid__col grid__col--portfolio grid__col--4-of-12 grid__col--fluid">
                    <VideoPadding source="Opentensor_Wallet_Balance_A04_oomspw" bg="#EFEFEF" />
                </div>
                <div class="grid__col grid__col--portfolio grid__col--8-of-12 grid__col--fluid">
                    <ImageAuto source="s6bkvcomb2hflq99kfol" bg="#EFEFEF" />
                </div>
            </div>
            <div class="grid project__container project__content">
                <div class="grid__col grid__col--portfolio grid__col--8-of-12 grid__col--fluid">
                    <Slideshow :images="['dvd2xiu2ickjymwszjhe', 'a7uhkh4k73k54rjis7tz']" bg="#EFEFEF" :interval="2000" />
                </div>
                <div class="grid__col grid__col--portfolio grid__col--4-of-12 grid__col--fluid">
                    <VideoPadding source="Opentensor_Lottie_Overview_v2_jr5d0d" bg="#EFEFEF" />
                </div>
            </div>
        </section>

        <section class="project" id="securitas">
            <div class="grid project__header">
                <div class="grid__col grid__col--6-of-12">
                    <h2 class="p project__title">Securitas Brand Portal</h2>
                    <p class="serif">Web Design, Development</p>
                </div>
                <div class="grid__col grid__col--6-of-12">
                    <p class="p">Design and web development of online brand portal for the world's leading intelligent
                        protective services partner Securitas.</p>
                    <p class="indent">Securitas turned to Kurppa Hosk to develop a visual identity and communications
                        platform that fully reflected the company’s commitment to a human, progressive and innovative
                        approach to security.</p>
                    <p class="indent">Part of the project was the creation of an online brand portal for employees to use as
                        guidelines and best practices when using the new identity as well as for the public to be inspired
                        and explore the brand ingredients in a transparent manner.</p>
                    <p class="indent">Created during my employment at <a target="_blank"
                            href="https://www.kurppahosk.com">Kurppa Hosk</a>.</p>
                </div>
            </div>
            <div class="grid project__container">
                <div class="grid__col grid__col--portfolio grid__col--12-of-12 grid__col--fluid">
                    <ImageAuto source="securitas1_m2i9t0" bg="#000" />
                </div>
            </div>
            <div class="grid project__container project__content">
                <div class="grid__col grid__col--portfolio grid__col--6-of-12 grid__col--fluid">
                    <VideoPadding source="securitas_video1_ckxfy9_1_comp_smojum" bg="#000" />
                </div>
                <div class="grid__col grid__col--portfolio grid__col--6-of-12 grid__col--fluid">
                    <VideoPadding source="securitas_video1_ckxfy9_2_comp_mzv5as" bg="#000" />
                </div>
            </div>
        </section>

        <section class="project" id="remora">
            <div class="grid project__header">
                <div class="grid__col grid__col--6-of-12">
                    <h2 class="p project__title">Remora</h2>
                    <p class="serif">Identity, Typeface, Web Design, Web Development, 3D</p>
                </div>
                <div class="grid__col grid__col--6-of-12">
                    <p class="p">Remora is an american company creating a device for collecting carbon dioxide from
                        semi-trucks.</p>
                    <p class="indent">Kurppa Hosk was asked to help Remora with their new website and in the process create
                        an identity based on design choices made in the making of the website.</p>
                    <p class="indent">With the clear guideline of wanting to be different from other tech startups, the
                        identity is instead based on the industrial background of the company, making it impactful, bold but
                        still technology oriented.</p>
                </div>
            </div>
            <div class="grid project__container project__content">
                <div class="grid__col grid__col--portfolio grid__col--12-of-12">
                    <Slideshow :images="['remora_02_st4rdd', 'remora_01_l3cxdi', 'remora_04_rwz6l4', 'remora_06_nueeff']"
                        bg="#000" />
                </div>
            </div>
            <div class="grid project__container">
                <div class="grid__col grid__col--portfolio grid__col--12-of-12">
                    <ImageAuto source="remora_11_rm2f7l" bg="#000" />
                </div>
            </div>
            <div class="grid project__container">
                <div class="grid__col grid__col--portfolio grid__col--6-of-12 grid__col--fluid">
                    <ImageAuto source="remora_03_qdfges" bg="#000" />
                </div>
                <div class="grid__col grid__col--portfolio grid__col--6-of-12 grid__col--fluid">
                    <ImageAuto source="remora_12_lvukwu" bg="#000" />
                </div>
            </div>
            <div class="grid project__container">
                <div class="grid__col grid__col--portfolio grid__col--6-of-12 grid__col--fluid">
                    <ImageAuto source="remora_10_oixwsz" bg="#000" />
                </div>
                <div class="grid__col grid__col--portfolio grid__col--6-of-12 grid__col--fluid">
                    <ImageAuto source="remora_05_o3ocd0" bg="#000" />
                </div>
            </div>
            <div class="grid project__container">
                <div class="grid__col grid__col--portfolio grid__col--12-of-12">
                    <ImageAuto source="remora_09_jetnz2" bg="#000" />
                </div>
            </div>
        </section>

        <section class="project" id="odda">
            <div class="grid project__header">
                <div class="grid__col grid__col--6-of-12">
                    <h2 class="p project__title">ODDA</h2>
                    <p class="serif">Web Design, Web Development</p>
                </div>
                <div class="grid__col grid__col--6-of-12">
                    <p class="p">Design and development for the website of Stockholm based design studio ODDA.</p>
                    <p class="indent">ODDA was founded with the vision to create furniture and decor that breathes
                        sustainable and smart design throughout traditional crafts and modern techniques.</p>
                    <p class="indent">The obvious hero of the website would become the projects themselves, with focus and
                        space for the images to be experienced.</p>
                </div>
            </div>
            <div class="grid project__container project__content">
                <div class="grid__col grid__col--portfolio grid__col--12-of-12 grid__col--fluid">
                    <VideoPadding source="odda_iv7bh7" bg="#000" />
                </div>
            </div>
            <div class="grid project__container project__content">
                <div class="grid__col grid__col--portfolio grid__col--4-of-12 grid__col--fluid">
                    <ImagePadding source="odda3_d2flkt" bg="#000" />
                </div>
                <div class="grid__col grid__col--portfolio grid__col--8-of-12 grid__col--fluid">
                    <ImagePadding source="odda5_cyxoen" bg="#000" />
                </div>
            </div>
            <div class="grid project__container project__content">
                <div class="grid__col grid__col--portfolio grid__col--8-of-12 grid__col--fluid">
                    <ImagePadding source="odda6_xy2wte" bg="#000" />
                </div>
                <div class="grid__col grid__col--portfolio grid__col--4-of-12 grid__col--fluid">
                    <ImagePadding source="odda4_nyumvb" bg="#000" />
                </div>
            </div>
        </section>

        <section class="project" id="heart">
            <div class="grid project__header">
                <div class="grid__col grid__col--6-of-12">
                    <h2 class="p project__title">Heart Aerospace</h2>
                    <p class="serif">Web Design, Web Development</p>
                </div>
                <div class="grid__col grid__col--6-of-12">
                    <p class="p">Design and development for the website of Heart Aerospace.</p>
                    <p class="indent">Their mission is to create the fastest, most affordable and sustainable transportation
                        for regional travel to every corner of the world and will deliver the first ES-19 electric airliner
                        certified for commercial flight by 2026.</p>
                    <p class="indent">We helped them create a website presenting the ES-19 airliner, art directing 3D
                        renders of the aircraft and translating the identity into a digital context.</p>
                    <p class="indent">Created during my employment at <a target="_blank"
                            href="https://www.kurppahosk.com">Kurppa Hosk</a>.</p>
                </div>
            </div>
            <div class="grid project__container project__content">
                <div class="grid__col grid__col--portfolio grid__col--12-of-12 grid__col--fluid">
                    <ImagePadding source="heart1_cyrak1" bg="#000" />
                </div>
            </div>
            <div class="grid project__container project__content">
                <div class="grid__col grid__col--portfolio grid__col--8-of-12 grid__col--fluid">
                    <ImagePadding source="heart2_vcpgwz" bg="#000" />
                </div>
                <div class="grid__col grid__col--portfolio grid__col--4-of-12 grid__col--fluid">
                    <ImagePadding source="heart3_fqrzc7" bg="#000" />
                </div>
            </div>
            <div class="grid project__container">
                <div class="grid__col grid__col--portfolio grid__col--12-of-12 grid__col--fluid">
                    <VideoAuto source="heart3_m42fnq" bg="#000" />
                </div>
            </div>
        </section>

        <section class="project" id="happybob">
            <div class="grid project__header">
                <div class="grid__col grid__col--6-of-12">
                    <h2 class="p project__title">Happy Bob</h2>
                    <p class="serif">Web Design, UI</p>
                </div>
                <div class="grid__col grid__col--6-of-12">
                    <p>Kurppa Hosk created the brand identity for the blood glucose tracking app Happy Bob.</p>
                    <p class="indent">I was involved in the forming of a digital toolkit, creating rules and concepts for
                        how the playful brand should be applied in digital touch-points including website, app and
                        dashboard.</p>
                    <p class="indent">We contributed with a design concept for their web based dashboard "Dashbob", where we
                        brought the playful expression of the identity into an otherwise strict world of UI elements as well
                        as visual ideation for an interactive diagram that helps the users to get a clear overview on their
                        glucose levels while performing different activities.</p>
                    <p class="indent">Created during my employment at <a target="_blank"
                            href="https://www.kurppahosk.com">Kurppa Hosk</a>.</p>
                </div>
            </div>
            <div class="grid project__container">
                <div class="grid__col grid__col--portfolio grid__col--12-of-12 grid__col--fluid">
                    <ImageAuto source="ne1t7pjdjguwompm6mgd" bg="#CCE0FF" />
                </div>
            </div>
            <div class="grid project__container">
                <div class="grid__col grid__col--portfolio grid__col--12-of-12 grid__col--fluid">
                    <VideoAuto source="c3ipjad9nh15vijt2tni" bg="#CCE0FF" />
                </div>
            </div>
            <div class="grid project__container">
                <div class="grid__col grid__col--portfolio grid__col--12-of-12 grid__col--fluid">
                    <ImageAuto source="hb1_dqj9nu" bg="#CCE0FF" />
                </div>
            </div>
            <div class="grid project__container project__content">
                <div class="grid__col grid__col--portfolio grid__col--6-of-12 grid__col--fluid">
                    <ImagePadding source="hb3_ge1trx" bg="#CCE0FF" />
                </div>
                <div class="grid__col grid__col--portfolio grid__col--6-of-12 grid__col--fluid">
                    <ImagePadding source="hb4_xotlgb" bg="#CCE0FF" />
                </div>
            </div>
        </section>

        <section class="project" id="antonalvarez">
            <div class="grid project__header">
                <div class="grid__col grid__col--6-of-12">
                    <h2 class="p project__title">Anton Alvarez</h2>
                    <p class="serif">Web Design, Web Development</p>
                </div>
                <div class="grid__col grid__col--6-of-12">
                    <p class="p">Design and web development for sculptor and artist Anton Alvarez.</p>
                </div>
            </div>
            <div class="grid project__container project__content">
                <div class="grid__col grid__col--portfolio grid__col--12-of-12 grid__col--fluid">
                    <VideoPadding source="antonalvarez_video1_dn6kj8" bg="#000" />
                </div>
            </div>
            <div class="grid project__container project__content">
                <div class="grid__col grid__col--portfolio grid__col--12-of-12 grid__col--fluid">
                    <ImagePadding source="antonalvarez5_uqiqvt" bg="#000" />
                </div>
            </div>
            <div class="grid project__container project__content">
                <div class="grid__col grid__col--portfolio grid__col--4-of-12 grid__col--fluid">
                    <ImagePadding source="antonalvarez4_lvy7yl" bg="#000" />
                </div>
                <div class="grid__col grid__col--portfolio grid__col--8-of-12 grid__col--fluid">
                    <ImagePadding source="antonalvarez2_bavpca" bg="#000" />
                </div>
            </div>
        </section>

        <section class="project" id="rickardsund">
            <div class="grid project__header">
                <div class="grid__col grid__col--portfolio grid__col--6-of-12">
                    <h2 class="p project__title">Rickard Sund</h2>
                    <p class="serif">Web Design, Web Development</p>
                </div>
                <div class="grid__col grid__col--portfolio grid__col--6-of-12">
                    <p class="p">Collaborative design and web development for photographer Rickard Sund.</p>
                </div>
            </div>
            <div class="grid project__container project__content">
                <div class="grid__col grid__col--portfolio grid__col--12-of-12">
                    <VideoPadding source="rickardsund_video1_xytjpd" bg="#000" />
                </div>
            </div>
            <div class="grid project__container project__content">
                <div class="grid__col grid__col--portfolio grid__col--4-of-12">
                    <ImagePadding source="rickardsund_1_fzvvol" bg="#000" />
                </div>
                <div class="grid__col grid__col--portfolio grid__col--8-of-12">
                    <ImagePadding source="rickardsund_3_xdi5ue" bg="#131313" />
                </div>
            </div>
            <div class="grid project__container project__content">
                <div class="grid__col grid__col--portfolio grid__col--8-of-12">
                    <ImagePadding source="rickardsund_4_pkzes3" bg="#131313" />
                </div>
                <div class="grid__col grid__col--portfolio grid__col--4-of-12">
                    <ImagePadding source="rickardsund_2_c9wk1k" bg="#000" />
                </div>
            </div>
        </section>

        <section class="project" id="svp">
            <div class="grid project__header">
                <div class="grid__col grid__col--6-of-12">
                    <h2 class="p project__title">Sparre Vreede Partners</h2>
                    <p class="serif">Web Design, Web Development</p>
                </div>
                <div class="grid__col grid__col--6-of-12">
                    <p class="p">Design and web development for construction management company Sparre Vreede Partners.</p>
                    <p class="indent">In collaboration with <a target="_blank" href="https://www.christopherwest.se">Studio
                            Christopher West</a>.</p>
                </div>
            </div>
            <div class="grid project__container project__content">
                <div class="grid__col grid__col--portfolio grid__col--12-of-12">
                    <ImagePadding source="svp1_kowfqn" bg="#000" />
                </div>
            </div>
            <div class="grid project__container project__content">
                <div class="grid__col grid__col--portfolio grid__col--12-of-12">
                    <ImagePadding source="svp2_pbpuag" bg="#000" />
                </div>
            </div>
        </section>

        <section class="project" id="designstudies">
            <div class="grid project__header">
                <div class="grid__col grid__col--6-of-12">
                    <h2 class="p project__title">Design Studies</h2>
                    <p class="serif">Web Design, Web Development</p>
                </div>
                <div class="grid__col grid__col--6-of-12">
                    <p class="p">Design and web development for product design company Design Studies.</p>
                    <p class="indent">In collaboration between me and Alf Arén as <a target="_blank"
                            href="https://www.ekstra.studio">Ekstra</a>.</p>
                </div>
            </div>
            <div class="grid project__container project__content">
                <div class="grid__col grid__col--portfolio grid__col--12-of-12">
                    <ImagePadding source="designstudies1_utehv7" bg="#000" />
                </div>
            </div>
            <div class="grid project__container project__content">
                <div class="grid__col grid__col--portfolio grid__col--6-of-12">
                    <ImagePadding source="designstudies2_v35cdo" bg="#000" />
                </div>
                <div class="grid__col grid__col--portfolio grid__col--6-of-12">
                    <ImagePadding source="designstudies3_tangmw" bg="#000" />
                </div>
            </div>
            <div class="grid project__container project__content">
                <div class="grid__col grid__col--portfolio grid__col--12-of-12">
                    <VideoPadding source="designstudies_video1_lwaujc" bg="#000" />
                </div>
            </div>
        </section>

        <section class="project" id="jamska">
            <div class="grid project__header">
                <div class="grid__col grid__col--6-of-12">
                    <h2 class="p project__title">Jamska</h2>
                    <p class="serif">Personal, Web Design, Identity, Web Development, Photography</p>
                </div>
                <div class="grid__col grid__col--6-of-12">
                    <p class="p">Design, web development, concept and photography for graduation project Jamska at Beckmans
                        College of Design.</p>

                    <p class="indent">Jamska is an audio-visual story about the peculiar and unique dialect jamska,
                        originating from the northern parts of Sweden.</p>

                    <p class="indent">On the basis of informing about the existence of jamska and increase the presence of
                        the dialect online, the result of the project is a website for the jamska to survive and be
                        experienced on.</p>

                    <p class="indent">The project thus also serves as an attempt to explore and demonstrate the potential of
                        websites as an interactive story-telling medium.</p>
                </div>
            </div>
            <div class="grid project__container project__content">
                <div class="grid__col grid__col--portfolio grid__col--12-of-12">
                    <VideoPadding source="jamska_video2_azqpwk" bg="#000" />
                </div>
            </div>
            <div class="grid project__container project__content">
                <div class="grid__col grid__col--portfolio grid__col--4-of-12">
                    <ImagePadding source="jamska_4_afn9w9" bg="#000" />
                </div>
                <div class="grid__col grid__col--portfolio grid__col--8-of-12">
                    <ImagePadding source="jamska_2_hqldyj" bg="#000" />
                </div>
            </div>
            <div class="grid project__container">
                <div class="grid__col grid__col--portfolio grid__col--12-of-12">
                    <ImageAuto source="jamska_6_skzjnj" />
                </div>
            </div>
            <!-- <div class="grid project__container">
            <div class="grid__col grid__col--12-of-12">
                <ImageAuto source="jamska_5.jpg" />
            </div>
        </div> -->
            <div class="grid project__container project__content">
                <div class="grid__col grid__col--portfolio grid__col--8-of-12">
                    <ImagePadding source="jamska_7_ro5jgs" bg="#000" />
                </div>
                <div class="grid__col grid__col--portfolio grid__col--4-of-12">
                    <ImagePadding source="jamska_3_gt5x2x" bg="#000" />
                </div>
            </div>
        </section>

        <section class="project" id="happify">
            <div class="grid project__header">
                <div class="grid__col grid__col--6-of-12">
                    <h2 class="p project__title">Happify</h2>
                    <p class="serif">Web Design, UI</p>
                </div>
                <div class="grid__col grid__col--6-of-12">
                    <p class="p">Design for health app Happify. The idea of the app is to give the user instructions and
                        scheduled assignments on how to improve your physical and mental health.</p>
                    <p class="indent">Happify wanted Kurppa Hosk to investigate how their current UI design could be
                        reworked into a more sophisticated and approachable style, which resulted in us creating a new,
                        warmer color scheme, new typographic treatment and a new visual approach in general that would
                        inspire Happify's internal work in terms of branding and future design work of their other apps as
                        well.</p>
                    <p class="indent"></p>
                    <p class="indent">Created during my employment at <a target="_blank"
                            href="https://www.kurppahosk.com">Kurppa Hosk</a>.</p>
                </div>
            </div>
            <div class="grid project__container project__content">
                <div class="grid__col grid__col--portfolio grid__col--12-of-12">
                    <ImageAuto source="secr_01_1_ila4ky" />
                </div>
            </div>
            <div class="grid project__container project__content">
                <div class="grid__col grid__col--portfolio grid__col--12-of-12">
                    <ImageAuto source="secr_02_1_ovswqw" />
                </div>
            </div>
        </section>

        <section class="project" id="bap">
            <div class="grid project__header">
                <div class="grid__col grid__col--6-of-12">
                    <h2 class="p project__title">Between Architecture & People</h2>
                    <p class="serif">Identity, Web Design, Web Development</p>
                </div>
                <div class="grid__col grid__col--6-of-12">
                    <p class="p">Design for architect firm Between Architecture & People.</p>
                    <p class="indent">In collaboration between me and Alf Arén as <a href="https://www.ekstra.studio"
                            target="_blank">Ekstra</a> and <a href="https://martinkozak.dk/" target="_blank">Martin
                            Kozak</a>.</p>
                </div>
            </div>
            <div class="grid project__container project__content">
                <div class="grid__col grid__col--portfolio grid__col--12-of-12">
                    <VideoPadding source="bap_video1_dlsaov" bg="#000" />
                </div>
            </div>
            <div class="grid project__container project__content">
                <div class="grid__col grid__col--portfolio grid__col--6-of-12">
                    <ImagePadding source="Frame_49_l7svvh" bg="#1D1D1D" />
                </div>
                <div class="grid__col grid__col--portfolio grid__col--6-of-12">
                    <VideoPadding source="bap_video2_nzduko" bg="#000" />
                </div>
            </div>
        </section>

        <section class="project" id="dinesen">
            <div class="grid project__header">
                <div class="grid__col grid__col--6-of-12">
                    <h2 class="p project__title">Dinesen</h2>
                    <p class="serif">Art Direction, Editorial, Packaging</p>
                </div>
                <div class="grid__col grid__col--6-of-12">
                    <p class="p">Ekstra had the oppurtunity to work with danish wood manufacturer Dinesen on various
                        projects.</p>
                    <p class="indent">For the launch of Dinesen's product line "Ash Wood", we created a campaign that
                        reflected their way of working in close collaboration with architects and designers. We teamed up
                        with Studio Kleiner and documentary film photographer Linn Alluna to create a series of images and
                        interviews that would show all aspects of working on a collaboration with Dinesen.</p>
                    <p class="indent">For the launch of Dinesen's own furniture series "Dinesen Collection", we designed
                        flyers and the bottle for their wood oil "DD–2".</p>
                    <p class="indent">In collaboration between me and Alf Arén as <a target="_blank"
                            href="https://www.ekstra.studio">Ekstra</a>, <a target="_blank"
                            href="https://www.studiokleiner.com">Studio Kleiner</a> and Linn Alluna.</p>
                </div>
            </div>
            <div class="grid project__container">
                <div class="grid project__container">
                    <div class="grid__col grid__col--portfolio grid__col--12-of-12 grid__col--fluid">
                        <VideoAuto source="Thulstrup-v5_comp_ywpwkx" bg="#000" />
                    </div>
                </div>
                <div class="grid__col grid__col--portfolio grid__col--4-of-12">
                    <ImageAuto source="dinesen_photo03_ejvekn" bg="#000" />
                </div>
                <div class="grid__col grid__col--portfolio grid__col--4-of-12">
                    <ImageAuto source="dinesen_photo02_yko24l" bg="#000" />
                </div>
                <div class="grid__col grid__col--portfolio grid__col--4-of-12">
                    <ImageAuto source="dinesen_photo01_nvvv6t" bg="#000" />
                </div>
            </div>
            <div class="grid project__container project__content">
                <div class="grid__col grid__col--portfolio grid__col--12-of-12">
                    <Slideshow :images="['dinesen03_qnm60c', 'dinesen02_ephqld', 'dinesen01_zfelrl']" bg="#000" />
                </div>
            </div>
            <div class="grid project__container">
                <div class="grid__col grid__col--portfolio grid__col--6-of-12">
                    <ImageAuto source="dinesen_bottle_tqujwk" bg="#000" />
                </div>
                <div class="grid__col grid__col--portfolio grid__col--6-of-12">
                    <ImageAuto source="dinesen_posters01_u89xkk" bg="#000" />
                </div>
            </div>
        </section>

        <section class="project" id="modular">
            <div class="grid project__header">
                <div class="grid__col grid__col--6-of-12">
                    <h2 class="p project__title">Eurorack Modules</h2>
                    <p class="serif">Personal, Product Design, UI</p>
                </div>
                <div class="grid__col grid__col--6-of-12">
                    <p class="p">Personal work-in-progress project involving front panel designs for DIY modules in my own
                        modular synth rack. </p>
                    <p class="indent">Using existent PCB layouts from DIY kits, the panels are designed with consistency in
                        mind, with the essential goal of creating a design system to be used when creating the modules.</p>
                    <p class="indent">Using Figma; pots, jacks, LEDs and buttons are organized into components. The modules
                        are then categorized with color coding based on their function, making it intuitive for the eye to
                        find the next patching path without squinting too much.</p>
                </div>
            </div>
            <div class="grid project__container">
                <div class="grid__col grid__col--portfolio grid__col--12-of-12">
                    <ImageAuto source="modular1_yqizej" />
                </div>
            </div>
        </section>
        <footer>
            <div class="grid">
                <div class="grid__col grid__col--6-of-12">
                    <p class="label color--tint">Copyright © {{ currentYear }} Tobias Gerhardsson. All rights reserved.</p>
                </div>
                <div class="grid__col grid__col--6-of-12 text-right"><span class="label hover" @click="scrollToTop">
                        <Icon icon="arrow-up" /> Back to top
                    </span></div>
            </div>
        </footer>
    </div>
</template>

<script>
import ImageAuto from '@/components/Image'
import VideoAuto from '@/components/VideoAuto'
import VideoPadding from '@/components/VideoPadding'
import ImagePadding from '@/components/ImagePadding'
import Slideshow from '@/components/Slideshow'
import Icon from '@/components/Icon'
export default {
    components: {
        VideoPadding,
        ImagePadding,
        ImageAuto,
        VideoAuto,
        Slideshow,
        Icon
    },
    computed: {
        currentYear() {
            return new Date().getFullYear()
        }
    },
    methods: {
        scrollToTop() {
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        }
    }
}
</script>

<style lang="scss" scoped>
.portfolio {
    padding-top: 32px;
}

.project {
    padding-bottom: $grid-gutter-width--desktop * 16;
}

.project:first-child .project__header {
    &:before {
        display: none;
    }
}

.project__title+.serif {
    margin-bottom: $grid-gutter-width--desktop * 4;
}

.project__header {
    padding-top: $grid-gutter-width--desktop;
    margin-bottom: $grid-gutter-width--desktop * 4;
    position: relative;
    // &:before {
    //     position:absolute;
    //     content:"";
    //     display:block;
    //     height:1px;
    //     width:calc(100% - #{$grid-gutter-width--desktop * 2});
    //     top:0;
    //     left:$grid-gutter-width--desktop;
    // }
}

.project__container {
    margin-bottom: $grid-gutter-width--desktop;
}

.project__content {
    height: 50vw;

    .grid__col {
        height: 100%;
    }
}

.label {
    padding: 0;
}</style>