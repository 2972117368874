import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Resume from '../views/Resume.vue'
import Portfolio from '../views/Portfolio.vue'

import store from '../store/index.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Tobias Gerhardsson – Graphic Designer & Front-End Developer',
      metaTags: [
        {
          name: 'description',
          content: 'Tobias is a graphic designer with interest and knowledge in front-end development. Born and raised in the northern parts of Sweden he early on found an interest in computers and their creative possibilities which gradually shaped into a never ending love for graphic design.'
        },
        {
          property: 'og:description',
          content: 'Tobias is a graphic designer with interest and knowledge in front-end development. Born and raised in the northern parts of Sweden he early on found an interest in computers and their creative possibilities which gradually shaped into a never ending love for graphic design.'
        }
      ]
    },
    children: [
      {
        path: '/portfolio',
        name: 'Portfolio',
        component: Portfolio,
        meta: { 
            title: 'Work – Tobias Gerhardsson',
            metaTags: [
              {
                name: 'description',
                content: 'Tobias is a graphic designer with interest and knowledge in front-end development. Born and raised in the northern parts of Sweden he early on found an interest in computers and their creative possibilities which gradually shaped into a never ending love for graphic design.'
              },
              {
                property: 'og:description',
                content: 'Tobias is a graphic designer with interest and knowledge in front-end development. Born and raised in the northern parts of Sweden he early on found an interest in computers and their creative possibilities which gradually shaped into a never ending love for graphic design.'
              }
            ],
          requiresAuth: true 
        }
      }
    ]
  },
  {
    path: '/resume',
    name: 'Resume',
    component: Resume,
    meta: {
      title: 'About – Tobias Gerhardsson',
      metaTags: [
        {
          name: 'description',
          content: 'Tobias is a graphic designer with interest and knowledge in front-end development. Born and raised in the northern parts of Sweden he early on found an interest in computers and their creative possibilities which gradually shaped into a never ending love for graphic design.'
        },
        {
          property: 'og:description',
          content: 'Tobias is a graphic designer with interest and knowledge in front-end development. Born and raised in the northern parts of Sweden he early on found an interest in computers and their creative possibilities which gradually shaped into a never ending love for graphic design.'
        }
      ]
    },
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.state.isLoggedIn) {
      next({
        path: '/',
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router
