<template>
                <figure class="full-bleed" :style="backgroundColor">
                    <div class="figure__inner">
                        <cld-video cloud-name="tobiasgerhardsson-com" autoplay='true' loop='true' muted='true' playsinline :public-id="imageURL" autoplayMode="on-visible" :quality="50" />
                    </div>
                </figure>
</template>

<script>
export default {
    props: ["bg", "source"],
    computed: {
        backgroundColor(){
            return `background-color:${this.bg};`
        },
        imageURL(){
            return `portfolio/${this.source}`
        }
    }
}
</script>

<style lang="scss" scoped>
    figure.full-bleed {
        background-color:#f0f0f0;
        height:100%;
        width:100%;
        position: relative;
        > .figure__inner {
            position:absolute;
            top:12%;
            left:12%;
            right:12%;
            bottom:12%;
        }
        img, video {
            object-fit:contain;
            width:100%;
            height:100%;
        }
    }
</style>