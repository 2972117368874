<template>
    <i :class="typeClass"></i>
</template>

<script>
export default {
    props: ["icon", "align"],
    computed: {
        typeClass(){
            let result = "";
            result += "i--" + this.icon;
            if (this.align) {
                result += " align--" + this.align;
            }
            return result
        }
    }
}
</script>

<style lang="scss" scoped>
    i {
        position:relative;
        display: inline-block;
        font-style:normal;
        font-size:0.85em;
        top:-0.079em;
        margin-right:0.25em;
        &.align--left {
            margin-right:0.25em;
        }
        &.align--right {
            margin-right:0;
            margin-left:0.25em;
        }
    }
    .i--arrow-down {
        &.align--left {
            &:after {
                margin-right:0.25em;
            }
        }
        &.align--right {
            &:after {
                margin-left:0.25em;
            }
        }
            &:after {
                margin-right:0.25em;
                content: "▼";
                display:inline-block;
                transform:translateY(2.5%);
            }
    }
    .i--arrow-up {
            &:after {
                content: "▼";
                transform:rotate(180deg);
                display:inline-block
            }
    }
    .i--arrow-right {
            &:after {
                transform:translateY(5%);
                content: "▶";
                display:inline-block;
            }
    }
    .i--arrow-left {
            &:after {
                transform:translateY(-10%) rotate(180deg);
                content: "▶";
                display:inline-block;
            }
    }
    .i--cross {
        &:after {
            content:"\00d7";
            font-size:1.5em;
            position:relative;
            display:inline-block;
            transform:translateY(110%);
            top:0;
        }
    }
    .i--error {
            &:after {
                content:"";
                transform:translateY(10%);
                display:inline-block;
                width:0.85em;
                height:0.85em;
                background:red;
                border-radius:50px;
            }
    }
    .i--success {
            &:after {
                content:"";
                transform:translateY(10%);
                display:inline-block;
                width:0.85em;
                height:0.85em;
                background:lime;
                border-radius:50px;
            }
    }
    .i--square {
            &:after {
                content:"";
                transform:translateY(10%);
                display:inline-block;
                min-width:1em;
                min-height:1em;
                background:black;
                margin-right:0.25em;
            }
    }
</style>