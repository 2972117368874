<template>
  <form class="login" @submit.prevent="login" @keypress.enter="login">
    <div class="message-row" :class="{'is-active': isFocus}">
        <div v-if="!isLoggedIn" class="label blink"><TGIcon icon="arrow-down" />Login below to access portfolio</div>
        <div class="label" v-show="message" :class="messageType ? 'success-message':'error-message'"><TGIcon :icon="messageType ? 'success':'error'" /> {{message}}</div>
    </div>
    <div class="inputs" v-if="!isLoggedIn">
    <input
      spellcheck="false"
      autocomplete="false"
      v-model="form.password"
      placeholder="Enter password..."
      type="password"
      @focus="setFocus"
      @blur="setBlur"
      @keypress="clearMessage"
    />
    <input type="submit" value="Login">
    </div>
    <div v-if="isLoggedIn && !isPortfolio">
      <router-link to="/portfolio" class="label hover"><TGIcon icon="arrow-down" />Back to portfolio</router-link>
    </div>
  </form>
</template>

<script>
import TGIcon from "@/components/Icon";

export default {
  data() {
    return {
      isFocus: false,
      debug: true,
      errorCode: "",
      message: "",
      messageType: false,
      user: "",
      form: {
        email: "hello",
        password: "",
      },
    };
  },
  components: {
    TGIcon,
  },
  methods: {
    login() {
            this.message = "";
            if (this.phrase.indexOf(this.form.password) > -1) {
              this.messageType = true
              this.message = "Welcome!";
            setTimeout(() => {
                this.clearMessage();
                this.userLoggedIn();
            }, 1000);
            } else {
              this.messageType = false
              this.message = "The password is incorrect";
            }
    },
    clearMessage() {
      this.message = "";
    },
    userLoggedIn(){
        this.$store.commit("SET_LOGIN");
        this.$router.push({ path: 'portfolio' })
    },
    setFocus(){
      this.isFocus = true
    },
    setBlur(){
      this.isFocus = false
    }
  },
  computed: {
    isPortfolio(){
        return this.$route.name === 'Portfolio'
    },
    isLoggedIn(){
        return this.$store.state.isLoggedIn
    },
    phrase(){
        return process.env.VUE_APP_PASSWORD.split(' ');
    }
  }
};
</script>

<style lang="scss" scoped>
.label {
  line-height:100%;
}
.login {
  height: 80px;
  padding:$grid-gutter-width--desktop;
}
.blink {
  animation:blink 0.75s steps(2, start) infinite;
}
.inputs {
  display:flex;
}
@keyframes blink {
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
}
.message-row {
    display:flex;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    transition:border-color 0.1s ease;
    &.is-active {
      border-bottom: 1px solid #000;
    }
}
input {
  font-size: 1rem;
  line-height: 100%;
  letter-spacing: -0.03em;
  border: 0;
  appearance: none;
  background: transparent;
  width: 100%;
  padding-top: $grid-gutter-width--desktop;
  outline:0;
  transition: border-color 0.18s ease, opacity 0.18s ease;
  .is-active & {
      display:none;
    }
}

input[type=text], input[type=password] {
  width:100%;
}
input[type=submit] {
  width:100px;
  text-align:right;
  cursor:pointer;
  &:hover {
    color:#777;
  }
}

</style>