<template>
  <div class="home">
    <div class="header h1">
      <div class="grid">
        <div class="grid__col grid__col--12-of-12 uppercase">
          Tobias Gerhardsson<br />
          Graphic Designer & Front-End Developer
        </div>
        <div class="grid__col grid__col--9-of-12 grid__col--push-3-of-12">
          <span class="uppercase">Currently working as Senior Digital Designer at</span> <a class="serif" href="https://www.kurppahosk.com" target="_blank">Kurppa Hosk</a>
        </div>
        <div class="grid__col grid__col--12-of-12 serif">
          <a href="tel:+46(0)705176549">+46(0)705176549</a><br />
          <a href="mailto:tobias.gerhardsson@gmail.com">tobias.gerhardsson@gmail.com</a>
        </div>
      </div>
    </div>
    <div class="content" :class="{'is-active': isLoggedIn }">
      <Login :class="{'is-active': isLoggedIn}" />
      <router-view v-if="isLoggedIn"></router-view>
    </div>
  </div>
</template>

<script>
import Login from "@/components/Login";
export default {
  name: "Home",
  components: {
    Login,
  },
  computed: {
    isLoggedIn(){
      return this.$store.state.isLoggedIn && this.$route.name === 'Portfolio'
    }
  }
};
</script>

<style lang="scss" scoped>
.home {
  height: 100%;
}
nav {
  position:absolute;
  top:0;
  right:0;
  padding:$grid-gutter-width--desktop;
  z-index:99;
}
.header {
  width:100%;
  height:100%;
  position:fixed;
  display:flex;
  align-items: center;
  padding-bottom:8vh;
}
.content {
  background-color:white;
  transform:translateY(calc(100vh - 92px));
  width:100%;
  &.is-active {
    min-height:100vh;
    transition:transform .8s cubic-bezier(0.76, 0, 0.24, 1);
    transform:translateY(0);
  }
}
a {
  color:inherit;
  text-decoration:none;
  &:hover {
    opacity:0;
  }
}
</style>