<template>
                <figure class="image-padding" :class="{fill: fill}" :style="backgroundColor">
                    <div v-if="disableLazy" class="inner">
                        <cld-image 
                            :public-id="imageURL" 
                            width="100%"
                            height="100%"
                            >
                            </cld-image>
                        </div>
                        <cld-image v-if="!disableLazy"
                            :public-id="imageURL" 
                            loading="lazy"
                            width="100%"
                            height="100%"
                            >
                        <cld-placeholder type="predominant-color"></cld-placeholder>
                        </cld-image>
                </figure>
</template>

<script>
export default {
    props: ["bg", "source", "fill", "disableLazy"],
    computed: {
        backgroundColor(){
            return `background-color:${this.bg};`
        },
        imageURL(){
            return `portfolio/${this.source}`
        }
    }
}
</script>

<style lang="scss">
    .image-padding {
        background-color:#f0f0f0;
        height:100%;
        width:100%;
        position: relative;
        > .cld-image-wrapper, > .inner {
            position:absolute;
            top:12%;
            left:12%;
            right:12%;
            bottom:12%;
        }
    }
    .fill .cld-image-wrapper {
        top:0;
        left:0;
        right:0;
        bottom:0;
    }
    .image-padding img, .image-padding video {
        object-fit:contain;
        width:100%;
        height:100%;
    }
    .fill img, .fill video {
        object-fit:cover;
    }
</style>