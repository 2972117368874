<template>
    <div class="slideshow">
        <ImagePadding :source="currentImage" :disableLazy="true" :bg="bg">
        </ImagePadding>
    </div>
</template>

<script>
import ImagePadding from '@/components/ImagePadding'
export default {
    data() {
        return {
            imageIndex: 0,
        }
    },
    computed: {
        currentImage() {
            return this.images[this.imageIndex]
        }
    },
    components: {
        ImagePadding
    },
    props: {
        bg: {
            type: String,
            default: "black"
        },
        images: {
            type: Array,
            required: true
        },
        interval: {
            type: Number,
            default: 1000
        }
    },
    created() {
        setInterval(() => {
            if (this.imageIndex < this.images.length - 1) {
                this.imageIndex++;
            } else {
                this.imageIndex = 0
            }
        }, this.interval)
    }
}
</script>

<style lang="scss" scoped>
.slideshow {
    height: 100%;
}
</style>